import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, Input, Inject} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common' 
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/project/dashboard/nav/dashboardnav.component';
import { SocketService } from 'src/app/services/socket/socket.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  edit = false;
  fname = '';
  lname = '';
  dob = '';
  jobT = '';
  email = '';
  gender = 'male';
  description = '';
  phone = '';
  oldPassword = '';
  newPassword = '';
  img: any;
  idUser = null;
  contactInfo: any;
  imageFil = null;
  hide = true;
  hidebis = true;
  mdpValues = '';
  isCurrentuser:boolean = true;
  title = 'ngImageCrop';
  imageChangedEvent: any = '';
  
  //imageChangedEvent: any = '';
    //croppedImage: any = '';
  
    fileChangeEvent(event: any): void {
      const dialogRef = this.dialog.open(croppedimagedialog, {
        width: '700px',
        data: {event: event}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result !== null) {
          this.img = result.event;
          const reader = new FileReader();
          this.imageFil = result.fileToReturn;
          /*
          var files = event.target.files;

          // loop through files
          for (var i = 0; i < files.length; i++) {
            this.imageFil = files[i];
          }
          this.imageFil = files[i];
          */

          reader.readAsDataURL(this.imageFil);
            
          reader.onload = () => {
            // need to run CD since file load runs outside of zone
            this.cd.markForCheck();
            //this.img = reader.result;
          }; 
        }
      });
        //this.imageChangedEvent = event;
    }
    /*
    imageCropped(event: ImageCroppedEvent) {
        this.img = event.base64;
    }
    imageLoaded() {
        // show cropper 
    }
    cropperReady() {
        // cropper ready 
    }
    loadImageFailed() {
        // show message 
    }
    */
 
  

  constructor(public dialog: MatDialog, private authService: AuthService, private socketService: SocketService, private route: ActivatedRoute, private cd: ChangeDetectorRef, public datepipe: DatePipe, public dialogRef: MatDialogRef<croppedimagedialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.imageChangedEvent = data.event;
      this.idUser = data.idUser;
    /*
    this.route.paramMap.subscribe(params => {
      //console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      //console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      this.idUser = params.get('ID');
     
    });*/

  }

  onNoClick(): void {
    this.dialogRef.close();
    //this.projectDetails = this.firstFormGroup.get('projetName').value;
      
  }

  getCurrentUser(){
   // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.getInfoContact(this.idUser)
    .subscribe( dataContact => {
      this.contactInfo = dataContact;
      this.img = (this.contactInfo.imageUrl ? this.getAvatarlUrl(this.contactInfo.imageUrl) : '../../../../assets/img/default-avatar.jpg');
      this.fname =  this.contactInfo.givenName;
      this.lname =  this.contactInfo.familyName;
      if(this.contactInfo.birthDate) {
        if (this.contactInfo.birthDate.date) 
        {
          this.dob = this.contactInfo.birthDate.date;
        }
        else
        {
          this.dob = this.contactInfo.birthDate;
        }
      }
      //this.dob =  this.contactInfo.birthDate;
      this.jobT =  this.contactInfo.jobTitle;
      this.email =  this.contactInfo.email;
      this.gender =  this.contactInfo.gender;
      this.description =  this.contactInfo.description;
      this.phone =  this.contactInfo.telephone;

    }, (error) => {
        console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });

  }


  getMyInfo(){

    const userData = JSON.parse(localStorage.getItem('currentUser'));
    console.log(' Avatar --> '+userData.imageUrl);
    this.img = (userData.imageUrl ? this.getAvatarlUrl(userData.imageUrl) : '../../../../assets/img/default-avatar.jpg');
    this.authService.photoHeader.emit(userData.imageUrl);
    this.fname = userData.givenName;
    this.lname = userData.familyName;
    //console.log(' anniversaire -> '+JSON.stringify(userData.birthDate));
    if (userData.birthDate) {
      if (userData.birthDate.date) 
      {
        this.dob = userData.birthDate.date;
      }
      else
      {
        this.dob = userData.birthDate;
      }
    }
    this.jobT = userData.jobTitle;
    this.email = userData.email;
    this.gender = userData.gender;
    this.description = userData.description;
    this.phone = userData.telephone;
    

      //console.log('getCurrentUser this.gender  id ==> ',this.gender );
      
  }

  //  saveProfile() {
  //    this.authService.saveProfile(
  //      this.img,
  //      this.fname,
  //      this.lname,
  //      this.dob,
  //      this.jobT,
  //      this.email,
  //      this.gender,
  //      this.description,
  //      this.phone
  //     ){

  //     }
  //  }
  
  onKey(event: any) { // without type info
    this.mdpValues += event.target.value + ' | ';
  } 

  ChoseImage(event): void{  
   
    const reader = new FileReader();
    var files = event.files;

    // loop through files
    for (var i = 0; i < files.length; i++) {
      this.imageFil = files[i];
    }

    reader.readAsDataURL(this.imageFil);
      
    reader.onload = () => {
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
      this.img = reader.result;
    }; 

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  fctCancel(){
    this.edit = false;
    this.getMyInfo();
  }

  saveProfile() {
     
    let latest_date = null;
    var userId = JSON.parse(localStorage.getItem('currentUser'))['id'];
    var updated = false;
    var result = null;
    if (this.dob) {
      latest_date = this.datepipe.transform(this.dob, 'dd-MM-yyyy');
    }

    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.udapteUser((this.fname ? this.fname : ''), (this.lname ? this.lname : ''), (latest_date ? latest_date : null), (this.jobT ? this.jobT : ''), (this.email ? this.email : '')
      ,(this.gender ? this.gender : ''), (this.description ? this.description : ''), (this.phone ? this.phone : ''), (this.oldPassword ? this.oldPassword : ''), (this.newPassword ? this.newPassword : '')).subscribe( data => {
       //console.log('retour saveProfile '+JSON.stringify(data));
       this.edit = false; 
       this.getMyInfo();
       updated = true;
       result = data;
       alert('Modification(s) enregistré');
    }, (error) => {
        //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });


    if(this.imageFil) {
      this.authService.udapteUserImage(this.imageFil).subscribe( data => {
         //console.log('retour saveProfile '+JSON.stringify(data));
        this.getMyInfo();  
        this.socketService.sendInstant({
          id: "0",
          action: "0",
          type: "UPDATEPROFIL",
          author: userId,
          recipient: "",
          text: "",
          copy: "",
          private: false,
          res: data,
          project_lib: "",
          date: ""
        });
      }, (error) => {
          //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
          alert('une erreur est survenue');
      });
    
    } else if(updated) {
      this.socketService.sendInstant({
        id: "0",
        action: "0",
        type: "UPDATEPROFIL",
        author: userId,
        recipient: "",
        text: "",
        copy: "",
        private: false,
        res: result,
        project_lib: "",
        date: ""
      });
    }

  }

  ngOnInit() {
    const currentUser  = JSON.parse(localStorage.getItem('currentUser'));
      this.isCurrentuser = (currentUser.id == this.idUser || this.idUser == null) ? true : false;
      if(this.idUser)
        {
          this.getCurrentUser();
        } else {
          this.getMyInfo();
      }
    /*
    this.isCurrentuser = (currentUser.id == this.idUser || this.idUser == null) ? true : false;
    this.route.params.subscribe(routeParams => {
      if(this.idUser)
    {
      this.getCurrentUser();
    }
    else
    {
      this.getMyInfo();
    }
    });*/
    
  }

  

}

@Component({
  selector: 'croppedimagedialog.component',
  templateUrl: 'croppedimagedialog.component.html',
   styleUrls: ['croppedimagedialog.component.scss']
})
export class croppedimagedialog implements OnInit{
  

  projectUsers = [];
  userStatus = [];
  subjectGroup = [];
  projectId = null;
  arrayEmailInvitation = [];
  arrayEmailInvitationWithId = [];
  event: ImageCroppedEvent;
  imageChangedEvent: any = '';
  file :File;
  fileToReturn : any;
  projectDetails = null;

  constructor(
    public dialogRef: MatDialogRef<croppedimagedialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.imageChangedEvent = data.event;
  }

  imageCropped(event: ImageCroppedEvent) {
      //this.img = event.base64;
      this.event = event;
      //this.croppedImage = event.base64;
    
      this.fileToReturn = this.base64ToFile(
        event.base64,
        this.imageChangedEvent.target.files[0].name,
      )

      //return fileToReturn;
  }

  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageLoaded() {
      /* show cropper */
  }
  cropperReady() {
      /* cropper ready */
  }
  loadImageFailed() {
      /* show message */
  }

    ngOnDestroy() {
      //this._onDestroy.next();
      //this._onDestroy.complete();
    }

    ngOnInit() {
      this.arrayEmailInvitation = [];
    }

  validate(){
    //this.projectDetails['event'] = this.event.base64;
    //this.projectDetails['fileToReturn'] = this.fileToReturn;
    this.dialogRef.close({event:this.event.base64, fileToReturn : this.fileToReturn });
  }


  onNoClick(): void {
    this.dialogRef.close();
    //this.projectDetails = this.firstFormGroup.get('projetName').value;
      
  }


  nextStep(){
    //this.stepstyle = 2;
  }


}

/*
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  edit = false;
  fname = '';
  lname = '';
  dob = '';
  jobT = '';
  email = '';
  gender = 'male';
  description = '';
  phone = '';
  oldPassword = '';
  newPassword = '';
  img: any;
  idUser = null;
  contactInfo: any;
  imageFil = null;
  hide = true;
  hidebis = true;
  mdpValues = '';
  isCurrentuser:boolean = true;
  title = 'ngImageCrop';
  
  //imageChangedEvent: any = '';
    //croppedImage: any = '';
  
    fileChangeEvent(event: any): void {
      const dialogRef = this.dialog.open(croppedimagedialog, {
        width: '700px',
        data: {event: event}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result !== null) {
          this.img = result.event;
          const reader = new FileReader();
          this.imageFil = result.fileToReturn;

          reader.readAsDataURL(this.imageFil);
            
          reader.onload = () => {
            // need to run CD since file load runs outside of zone
            this.cd.markForCheck();
            //this.img = reader.result;
          }; 
        }
      });
        //this.imageChangedEvent = event;
    }

  

  constructor(public dialog: MatDialog, private authService: AuthService, private route: ActivatedRoute, private cd: ChangeDetectorRef, public datepipe: DatePipe) {
    
    this.route.paramMap.subscribe(params => {
      //console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      //console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      this.idUser = params.get('ID');
     
    });

  }

  getCurrentUser(){
   // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.getInfoContact(this.idUser)
    .subscribe( dataContact => {
      this.contactInfo = dataContact;
      this.img = (this.contactInfo.imageUrl ? this.getAvatarlUrl(this.contactInfo.imageUrl) : '../../../../assets/img/default-avatar.jpg');
      this.fname =  this.contactInfo.givenName;
      this.lname =  this.contactInfo.familyName;
      if(this.contactInfo.birthDate) {
        if (this.contactInfo.birthDate.date) 
        {
          this.dob = this.contactInfo.birthDate.date;
        }
        else
        {
          this.dob = this.contactInfo.birthDate;
        }
      }
      //this.dob =  this.contactInfo.birthDate;
      this.jobT =  this.contactInfo.jobTitle;
      this.email =  this.contactInfo.email;
      this.gender =  this.contactInfo.gender;
      this.description =  this.contactInfo.description;
      this.phone =  this.contactInfo.telephone;

    }, (error) => {
        console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });

  }


  getMyInfo(){

    const userData = JSON.parse(localStorage.getItem('currentUser'));
    //console.log(' Avatar --> '+userData.imageUrl);
    this.img = (userData.imageUrl ? this.getAvatarlUrl(userData.imageUrl) : '../../../../assets/img/default-avatar.jpg');
    this.fname = userData.givenName;
    this.lname = userData.familyName;
    //console.log(' anniversaire -> '+JSON.stringify(userData.birthDate));
    if (userData.birthDate) {
      if (userData.birthDate.date) 
      {
        this.dob = userData.birthDate.date;
      }
      else
      {
        this.dob = userData.birthDate;
      }
    }
    this.jobT = userData.jobTitle;
    this.email = userData.email;
    this.gender = userData.gender;
    this.description = userData.description;
    this.phone = userData.telephone;
    

      //console.log('getCurrentUser this.gender  id ==> ',this.gender );
      
  }
  
  onKey(event: any) { // without type info
    this.mdpValues += event.target.value + ' | ';
  } 

  ChoseImage(event): void{  
   
    const reader = new FileReader();
    var files = event.files;

    // loop through files
    for (var i = 0; i < files.length; i++) {
      this.imageFil = files[i];
    }

    reader.readAsDataURL(this.imageFil);
      
    reader.onload = () => {
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
      this.img = reader.result;
    }; 

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  fctCancel(){
    this.edit = false;
    this.getMyInfo();
  }

  saveProfile() {
     
    let latest_date = null;

    if (this.dob) {
      latest_date = this.datepipe.transform(this.dob, 'dd-MM-yyyy');
    }

    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.udapteUser((this.fname ? this.fname : ''), (this.lname ? this.lname : ''), (latest_date ? latest_date : null), (this.jobT ? this.jobT : ''), (this.email ? this.email : '')
      ,(this.gender ? this.gender : ''), (this.description ? this.description : ''), (this.phone ? this.phone : ''), (this.oldPassword ? this.oldPassword : ''), (this.newPassword ? this.newPassword : '')).subscribe( data => {
       //console.log('retour saveProfile '+JSON.stringify(data));
       this.edit = false; 
       this.getMyInfo();
       alert('Modification(s) enregistré');
    }, (error) => {
        //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });


    if(this.imageFil) {
      this.authService.udapteUserImage(this.imageFil).subscribe( data => {
         //console.log('retour saveProfile '+JSON.stringify(data));
        this.getMyInfo();  
      }, (error) => {
          //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
          alert('une erreur est survenue');
      });
    
    }

  }

  ngOnInit() {
    const currentUser  = JSON.parse(localStorage.getItem('currentUser'));
    this.isCurrentuser = (currentUser.id == this.idUser || this.idUser == null) ? true : false;
    this.route.params.subscribe(routeParams => {
      if(this.idUser)
    {
      this.getCurrentUser();
    }
    else
    {
      this.getMyInfo();
    }
    });
    
  }

  

}
*/
