import { Component, ViewEncapsulation, Input, AfterViewInit, ElementRef, OnInit, ViewChild, QueryList, ViewChildren } from "@angular/core";


@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReadMoreComponent implements OnInit{

  @Input() dataLength: number;
  @Input() text: string;
  @Input() iduser: string;
  @Input() type: string;
  @Input() isTransfered: boolean;
  formatedText = null;
  textWithLink = null;
  public isCollapsed: boolean = true;
  public isCollapsable: boolean = false;
  id = null;

  //@ViewChild('textContainer', {static: true}) textContainer!: ElementRef;
  //@ViewChildren('readMore') myDivs!: QueryList<ElementRef>;
  originalText: string = '';
  truncatedText: string = '';
  isTruncated: boolean = false;
  private readonly charactersToRemove = 30;
  
  constructor(private elementRef: ElementRef) {
  }

  isTextLongOrContainsThreeNewLines(): boolean {
    //console.log("texto "+(this.text.match(/\n/g) || []).length)
    if(this.type == 'comment'){
      return (this.text.length > 110 ) || ((this.text.match(/\n/g) || []).length >= 1);
    } else return this.text.length > this.dataLength || (this.text.match(/\n/g) || []).length >= 4;
  }

  ngOnInit(){
    this.id = JSON.parse(localStorage.getItem('currentUser'))['id'];
    var largeurEcran = window.innerWidth;
    const averageCharWidth = 11.4;
    const averageCharcomWidth = 9.6;
    var msgwidth = largeurEcran * 0.35;
    var commentwidth = largeurEcran * 0.75;
    var msgCharacters = Math.floor(msgwidth / averageCharWidth) * 4;
    var commentCharacters = Math.floor(commentwidth / averageCharcomWidth);
    this.dataLength = msgCharacters;

    if(this.isTransfered){
      this.text = "Message transféré : "+this.text;
    }
    //this.formatedText = this.truncate(this.text, this.dataLength);
    this.formatedText = this.text.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>")
    this.textWithLink = this.fullTextWithLink(this.text);

    

    //this.originalText = this.text;
    //this.truncatedText = this.makeLinksClickable(this.originalText);
    //setTimeout(() => this.checkTruncation(), 0); // Wait for the view to initialize
  }
  /*
  ngAfterViewInit(): void {
    setTimeout(() => {
      const divWidth = this.myDivs.first.nativeElement.offsetWidth;
    // Obtenez la largeur moyenne d'un caractère (en supposant une police monospaced)
    const averageCharWidth = 10; // Ajustez cette valeur en fonction de votre police et de sa taille
    // Calculez le nombre maximal de caractères en fonction de la largeur du div
    var maxCharacters = Math.floor(divWidth / averageCharWidth) * 4;
    this.dataLength = maxCharacters;
    //console.log("value "+this.dataLength)
    }, 200);
    
  }
  */


  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    //console.log("value "+value)

    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }

    // Finds starting and ending positions of quoted text
    // in double or single quotes with escape char support like \" \'
    var match;
    var patt = /(http?s:\/\/[^\s]+)/g;

    while (match = patt.exec(value)) {
      console.log(match.index + ' ' + patt.lastIndex);
      limit += patt.lastIndex - match.index;
    }


    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    //console.log("val "+val)
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");

  }

  fullTextWithLink(value: string) {
    return value.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }
  /*
  private makeLinksClickable(text: string): string {
    return text.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>");
  }

  
  private checkTruncation(): void {
    const container = this.textContainer.nativeElement.scrollHeight;
    console.log("carac "+container)
    const lineHeight = parseFloat(getComputedStyle(container).lineHeight);
    const maxHeight = lineHeight * 4;
    console.log("carac "+container)

    if (container.scrollHeight > maxHeight) {
      this.isTruncated = true;
      let truncatedText = this.originalText;
      while (container.scrollHeight > maxHeight) {
        truncatedText = truncatedText.slice(0, -this.charactersToRemove);
        this.truncatedText = this.makeLinksClickable(truncatedText) + '...';
        container.innerHTML = this.truncatedText;
      }
    }
  }
  */

  /*
  showFullText(): void {
    this.truncatedText = this.makeLinksClickable(this.originalText);
    this.isTruncated = false;
    this.textContainer.nativeElement.classList.add('expanded');
  }*/

}
