import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSelect } from '@angular/material';
import { ITransfer } from 'src/app/components/dashboard-menu/dashboard-menu.component';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { OtherOptionsComponent } from '../../other-options/other-options.component';
import { environment } from '../../../../../environments/environment';
import { requiredFileType } from 'src/app/app-file-upload/upload-file-validators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SocketService } from 'src/app/services/socket/socket.service';
import { MessageService } from 'src/app/services/message/message.service';
import { CopyListeDialog, RecipientListeDialog, SubjectListeDialog } from '../single-project.component';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {

  typeTransfer: string = '';
  othergroupe = 0;
  instantface = "";
  projectUsers = [];
  projectTags  = [];
  copiesusers = [];
  nbrcopies = 0;
  currentUser: any;
  projectmembers = "";
  arrayUserCopy = [];
  sendMessageForm: FormGroup;
  tempMemberUser = [];
  tempMember = [];
  private = false;
  selectprivate = false;
  selectcopies = false;
  allcopies = false;
  ID: any;
  selectteam = false;
  showsubject: boolean = true;
  submitted = false;
  ISendMessage = false;
  transfermsg = "";
  project: string;
  projectselected: string;
  projectLists: Array<any> = [];
  public projectCtrl: FormControl = new FormControl();
  saveProject = null;
  protected _onDestroy = new Subject<void>();
  typefileselected = "photo";
  filesinstant: any = [];
  projectId = null;
  libelle = "";
  notifyValue = "ALL";
  idmsg = null;
  typeDialog = 1;
  public recipientSearch: FormControl = new FormControl();
  public subjectCtrl: FormControl = new FormControl();
  saveTheme = null;
  addmissionclicked = 0;
  newProjectSubject = "";
  iddoc = null;
  @ViewChild('selectSubject', {static: false}) private selectSubject: MatSelect;
  @ViewChild('missionname', {static: false}) missionname: ElementRef;

  constructor(private authService: AuthService,
    public  dialog: MatDialog,
    public messageService: MessageService,
    private socketService: SocketService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TransferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITransfer) { 
      this.projectUsers = data.projectUsers;
      this.projectTags = data.projectTags;
      this.project = data.project;
      this.idmsg = data.idmsg;
      this.typeDialog = data.typeDialog;
      this.transfermsg = data.transfermsg;
      this.iddoc = data.iddoc;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
      this.ID = data.idprojet;
      this.sendMessageForm = this.formBuilder.group({
        subProject: ['', Validators.required],
        newSubProject: '',
        recipient: ['', Validators.required],
        inCopy: [],
        private: false,
        deadLine: null,
        general: null,
        relanceOne: null,
        relanceTwo: null,
        relanceTree: null,
        files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
      });
      if(this.typeDialog == 2){
        this.typeTransfer = "instant";
      } else this.typeTransfer = "face";
    }

  ngOnInit() {
    this.loadProject();
    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });
    this.resetQuestionFrom();
    this.subjectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSubjectSearch();
    });
    console.log("shoxn "+this.showsubject)
  }

  onSelectClosed(){
    if(this.sendMessageForm.value.subProject.libelle){
      this.showsubject = false;
      setTimeout(() => {
        const libsubjectvalue: HTMLElement = document.getElementById('libsubjectvalue') as HTMLElement
        libsubjectvalue.innerHTML = this.sendMessageForm.value.subProject.libelle;
      }, 0.5);
    }
  }

  fctListeRecipient(event: MouseEvent){
    //if(this.projectFull.isPrivate) return;
    const dialogRef = this.dialog.open(RecipientListeDialog, {
      panelClass: 'dialogsubject-container',
      data: { idProjet: this.ID, projectUsers: this.projectUsers},
      position: {
        top: '50vh', // Centrer verticalement
        left: '50%'/*
        top: `${event.clientY}px`,
        left: `${event.clientX}px`*/
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if (result) {
        this.sendMessageForm.patchValue({
          recipient: result.id
        });
        setTimeout(() => {
          const librecipientvalue: HTMLElement = document.getElementById('librecipientvalue') as HTMLElement
          librecipientvalue.innerHTML = result.givenName +" "+result.familyName;
        }, 0.5);
       } 
    });
  }

  fctListeCopies(event: MouseEvent){
    const dialogRef = this.dialog.open(CopyListeDialog, {
      panelClass: 'dialogsubject-container',
      data: { idProjet: this.ID, projectCopies: this.getUserListWithNoCurrentUser()},
      height: "calc(-80px + 100vh)",
      position: {
        top: '50vh', // Centrer verticalement
        left: '50%'/*
        top: `${event.clientY}px`,
        left: `${event.clientX}px`*/
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if (result) {
        console.log("ris : "+result.length);
        var tempMember = [];
        this.tempMemberUser = [];
        result.map(item=> {
          tempMember.push(item.id);
          this.tempMemberUser.push(item);
        })
        this.sendMessageForm.controls.inCopy.patchValue(tempMember);
       } 
    });
  }

  fctListeSuject(event: MouseEvent){
    const dialogRef = this.dialog.open(SubjectListeDialog, {
      panelClass: 'dialogsubject-container',
      data: { idProjet: this.ID, projectTags: this.projectTags},
      height: "height: calc(-80px + 100vh);",
      position: {
        top: '50vh', // Centrer verticalement
        left: '50%'/*
        top: `${event.clientY}px`,
        left: `${event.clientX}px`*/
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if (result) {
        this.sendMessageForm.patchValue({
           subProject: result
         });
         setTimeout(() => {
          const libsubjectvalue: HTMLElement = document.getElementById('libsubjectvalue') as HTMLElement
          libsubjectvalue.innerHTML = this.sendMessageForm.value.subProject.libelle;
        }, 0.5);
       } 
    });
  }

  addProjectToArray(){
    // this.arraySubject.push({libelle:this.newProjectSubject})
    this.projectselected = '/api/projects/' + this.projectId;
    var newProjectSubject = (<HTMLInputElement>document.getElementById("newProjectSubject")).value;
    if(newProjectSubject.trim() == "" || newProjectSubject.trim().length == 0){
      alert("Veuillez entrer un sujet"+this.project);
      return;
    }
    this.authService.addTagToProject({
      libelle:newProjectSubject,
      project:this.projectselected
    }).then((res)=>{
      this.newProjectSubject = "";
      newProjectSubject = "";
      this.addmissionclicked = 0;
      this.projectTags.push(res);
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
      this.sendMessageForm.get('subProject').setValue(res); 
      this.showsubject = false;
      setTimeout(() => {
        const libsubject: HTMLElement = document.getElementById('libsubject') as HTMLElement
        libsubject.innerHTML = this.sendMessageForm.value.subProject.libelle;
      }, 0.5);
      //this.selectSubject.close();
      //this.getProjectInfo();
    }).catch(err=>{
      console.log(err)
    });
  }

  clickaddmission(){
    this.addmissionclicked = 1;
    setTimeout(() => this.missionname.nativeElement.focus(), 0);
  }

  cancelSubject(){
    this.addmissionclicked = 0;
  }

  protected filterSubjectSearch() {
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
    }

    let search = this.subjectCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  getCurrentProject(projectId) {
    //console.log('getCurrentProject paramètre id ==> '+this.ID);

    if (!projectId) {
      return;
    }

    //this.initIoConnection(this.ID);

    this.authService.getTheProject(projectId)
      .subscribe(res => {
        this.projectmembers = "";
        var tmpArrayUser = res['userProject'];
        //var tmpArrayUser = tmpArrayUser.filter((item) => item.user != null);
        this.projectUsers = [];
        //alert("ok "+this.project_isparent);
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user != null){
            this.projectUsers.push(tmpArrayUser[i]);
          }
        }
        
        this.projectUsers.sort((a, b) => {
          if ( a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase() ){
            return -1;
          }
          if ( a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase() ){
            return 1;
          }
          return 0;
        });
        this.projectTags = res['tags'];


        this.projectTags.sort((a, b) => {
          if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
            return -1;
          }
          if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
            return 1;
          }
          return 0;
        })

      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  } 

  getColor(){
    switch(this.typeDialog){
      case 1: return '#57b031';
      case 2: return '#009dd0';
      case 3: return '#57b031';
      default: return '#009dd0';
    }
  }

  transferInstant(){
    this.sendQuestionInstant(this.transfermsg);
  }

  transferFace(){
    alert("envoi organisé");
  }

  sendQuestionInstant(messageTosendData) {
    console.log("iddoc : "+this.iddoc)
    this.projectselected = '/api/projects/' + this.projectId;
    this.authService.sendQuestionInstant(messageTosendData, this.projectselected, this.filesinstant, this.typefileselected, null, true, this.idmsg, this.iddoc).subscribe(res => {
      this.filesinstant = [];
      this.authService.obinstantMsgsent.next({"id": this.projectId, "text":messageTosendData, "date": res['dateCreated']});
      this.socketService.sendInstant({
        id: res['id'],
        action: this.projectId,
        type: "INSTANT",
        author: this.currentUser,
        recipient: "",
        text: messageTosendData,
        copy: "",
        private: false,
        res: res,
        date: res['dateCreated']
      });
      this.dialogRef.close({id: this.projectId, type: "instant", res: res});
      /*
      if(this.data.project.replace("/api/projects/", "") == this.projectId){
        this.dialogRef.close(res);
      } else this.dialogRef.close();*/
    },error => {
      alert("oups une erreur s'est produite!");
    });
  }


  protected filterProject() {
    if(this.saveProject == null){
      this.saveProject = this.projectLists;
    }

    let search = this.projectCtrl.value;
    if (!search) {
      this.projectLists = this.saveProject;
      return;
    } else {
      search = search.toLowerCase();
      this.projectLists = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  loadProject(url?: string){
    url = url ? url : undefined;
    var projectscopie = this.messageService.getListProject();
    this.projectLists = [...projectscopie];
    this.projectLists = this.projectLists.sort(function (a, b) {
      if (a.libelle.toLowerCase() < b.libelle.toLowerCase()) { return -1; }
      if (a.libelle.toLowerCase() > b.libelle.toLowerCase()) { return 1; }
      return 0;
  })
  }

  setPrivate() {
    if(this.private == true){
      //this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.get('private').setValue(false);
      this.private = false;
      this.notifyValue = "ALL";
      //this.tempMember = [];
      //this.copiesusers = [];
      //this.toggleAllSelection();
    } else {
      //this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.get('private').setValue(true);
      this.private = true;
      this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.get('private').setValue(true);
      this.private = true;
      this.notifyValue = "ALL";
      this.selectprivate = true;
      this.allcopies = false;
      this.tempMember = [];
      this.copiesusers = [];
      //this.allcopies = false;
    }
  }

  changeNotify(){
    if(this.notifyValue == "ALL"){
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.tempMember = [];
          this.selectprivate = false;
          this.copiesusers = [];
          this.allcopies = true;
          this.projectUsers.forEach(element => {
            if(this.currentUser != element.user.id){
              this.tempMember.push(element);
              this.copiesusers.push(element.user);
            }
          });
    } else if(this.notifyValue == "MEMBERS"){
      this.openOptionsModal();
    }
  }


  beforeSend(idproject, libelle){
    this.projectId = idproject;
    this.libelle = libelle;
    if(this.typeTransfer == "instant"){
      this.transferInstant();
    } else {
      this.typeDialog = 3;
      console.log("type "+this.notifyValue)
      this.getCurrentProject(idproject);
    }

  }

  selectType() {
    //alert('Choix sélectionné :'+ this.typeTransfer);
    if(this.typeTransfer == "face"){
      this.typeDialog = 1;
      /*
      this.othergroupe = 2;
      this.instantface = "";
      */
    } else {
      this.typeDialog = 2;
      /*
      this.othergroupe = 1;
      this.instantface = "";
      */
    }
  }

  selectOrganized(){

  }

  selectInstant(){
    
  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  onSubmitForm(customerData) {
    this.submitted = true;
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }
    
    if (this.private == true) {
      //this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.controls.private.patchValue(true)
    }
    //if (this.sendMessageForm.controls.inCopy.value[0] == 'copyAll') {
    
    if (this.allcopies == true) {
      var tempMember = [];
      this.projectUsers.map(item=>tempMember.push(item.user.id))
      this.sendMessageForm.controls.inCopy.patchValue(tempMember);
    }
    const tempMember2 = this.sendMessageForm.controls.inCopy.value;
    //const updatedCopy = tempMember2.filter((item: any) => (item !== "0" ));
    this.sendMessageForm.controls.inCopy.patchValue(tempMember2);

    this.sendMessageForm.controls.general.patchValue(false);

    //console.log(this.sendMessageForm.value);

    this.sendQuestion(this.sendMessageForm.value);
    this.submitted = false;
    this.selectprivate = false;
    this.copiesusers = [];
    this.resetQuestionFrom();
    this.showsubject = true;
    //this.selectRecipient.close();
    this.sendMessageForm.controls.inCopy.patchValue([]);
    alert("message transféré");
    this.dialogRef.close({id: this.projectId, type: "face"});
    /*
    if(this.data.project.replace("/api/projects/", "") == this.projectId){
      this.dialogRef.close({id: "this.projectId", type: "face"});
    } else this.dialogRef.close();*/
    //this.divHeaderTable.nativeElement.scrollIntoView();
   
  }

  changeRecipient(){
    this.private = false;
    this.getUserListWithNoCurrentUser();
  }

  sendQuestion(messageTosendData) {
    
    this.projectselected = '/api/projects/' + this.projectId;
    this.ISendMessage = true;
    this.authService.sendQuestion(
      this.transfermsg,
      messageTosendData.subProject,
      /*(messageTosendData.subProject != '' ? messageTosendData.subProject : messageTosendData.newSubProject),
      (messageTosendData.subProject != '' ? false : true),*/
      this.projectselected,
      messageTosendData.recipient,
      messageTosendData.inCopy,
      messageTosendData.private,
      true,
      messageTosendData.deadLine,
      messageTosendData.relanceOne,
      messageTosendData.relanceTwo,
      messageTosendData.relanceTree,
      null,//this.files,
      null,
      null,
      null,
      messageTosendData.general,
      "true",
      this.idmsg,
      this.iddoc
    )
    .subscribe(res => {
      //this.total += 1;
      this.authService.obreceiveMsgsent.next({"id": this.projectId, "text":this.transfermsg, "date": res['dateCreated'] });
      
      this.messageService.sendInstant({
        id: res['id'],
        action: this.projectId,
        type: "FACE",
        author: this.currentUser,
        recipient: messageTosendData.recipient,
        text: this.transfermsg,
        copy: messageTosendData.inCopy,
        private: messageTosendData.private,
        res: res,
        date: res['dateCreated']
      });
      console.log("finish")
      this.ISendMessage = false;
    
    },error => {
      alert("oups une erreur s'est produite!");
      this.ISendMessage = false;
    });
  }

  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }
  
  resetQuestionFrom(){
   this.sendMessageForm.get('newSubProject').setValue('');
   this.sendMessageForm.get('recipient').setValue('');
   this.sendMessageForm.get('recipient').setValidators([Validators.required]);
   this.sendMessageForm.get('recipient').updateValueAndValidity();
   this.sendMessageForm.get('inCopy').setValue([]);
   this.sendMessageForm.get('private').setValue(false);
 }

  openSubject(){
    this.showsubject = true;
    this.selectSubject.open()
  }

  changesub(){
    //this.textArea.focus();
    //this.toggleAllSelection();
    this.showsubject = false;
    setTimeout(() => {
      const libsubject: HTMLElement = document.getElementById('libsubject') as HTMLElement
      libsubject.innerHTML = this.sendMessageForm.value.subProject.libelle;
    }, 0.5);
    //document.getElementById("descp").focus();
    //this.elementRef.nativeElement.focus();
    //alert("yeees");
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  getUserListWithNoCurrentUser(){
    if(!this.projectUsers) return [];
    this.arrayUserCopy = [];
    for (var i = 0; i < this.projectUsers.length; i++) {
      if (this.sendMessageForm.controls.recipient.value) {
        if(this.projectUsers[i].user.id != this.currentUser && this.sendMessageForm.controls.recipient.value != this.projectUsers[i].user.id /*&& this.projectUsers[i].status == 'ACCEPTED'*/){
          //arrayUser.push(this.projectUsers[i]);
          this.arrayUserCopy.push(this.projectUsers[i]);
          console.log(this.projectUsers[i].user.id);
        }
      }
      else if(this.projectUsers[i].user.id != this.currentUser && this.projectUsers[i].status == 'ACCEPTED'){
        //arrayUser.push(this.projectUsers[i]);
        this.arrayUserCopy.push(this.projectUsers[i]);
      }
    }
    return this.arrayUserCopy;
  }

  openOptionsModal(){
    //console.log('ID du project subject =>'+ this.ID);
    var copies = this.getUserListWithNoCurrentUser();
    this.nbrcopies = copies.length;
    const dialogRef = this.dialog.open(OtherOptionsComponent, {
      width: '300px',
      data:{idProjet: this.ID, private: this.private, copies: copies, selected: this.tempMember},
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.value === "private"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(true);
          this.copiesusers = [];
          this.private = true;
          this.selectprivate = true;
          this.allcopies = false;
        } else if(result.value === "notprivate"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.selectprivate = true;
          this.tempMember = [];
          this.copiesusers = [];
          this.toggleAllSelection();
        } else if(result.value === "all"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.tempMember = [];
          this.selectprivate = false;
          this.selectteam = true;
          this.copiesusers = [];
          this.allcopies = true;
          this.projectUsers.forEach(element => {
            if(this.currentUser != element.user.id){
              this.tempMember.push(element);
              this.copiesusers.push(element.user);
            }
          });
        } else if(result.value === "deadline"){
          this.sendMessageForm.get('deadLine').setValue(result.echeance); 
          this.sendMessageForm.get('relanceOne').setValue(result.Relance1);
          this.sendMessageForm.get('relanceTwo').setValue(result.Relance2);
          this.sendMessageForm.get('relanceTree').setValue(result.Relance3);
        } else if(result.value === "copy"){
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.selectcopies = false;
          this.selectprivate = false;
          this.allcopies = false;
          //this.toggleAllSelection();
          var copiesselected = result.copiesselected;
          this.tempMember = [];
          this.copiesusers = [];
          copiesselected.forEach(item => {
            this.projectUsers.forEach(element => {
              if(item == element.user.id){
                this.tempMember.push(item);
                this.copiesusers.push(element.user);
                //console.log("us "+element.user.fullName);
              }
            });
          });/*
          const elementtous: HTMLElement = document.getElementById('divtous') as HTMLElement
          elementtous.innerHTML = '';*/
          this.sendMessageForm.controls.inCopy.patchValue(this.tempMember);
        }
      }
      //console.log('The dialog was closed result '+result);
        //TO Do dialog ajout membre 
        //console.log(dialogRef.componentInstance.chosenSubject);
        //this.optionselected = dialogRef.componentInstance.chosenSubject;
    });
  }

  toggleAllSelection() {
      if (this.arrayUserCopy.length === this.sendMessageForm.get("inCopy").value.filter(item => item != "0").length) {
        this.sendMessageForm.controls.inCopy.patchValue([]);
      } else {
        this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
      }
  }

}
