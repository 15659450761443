// share-dialog.component.ts
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './sharedialog.component.html',
  styleUrls: ['./sharedialog.component.scss']
})
export class ShareDialogComponent {
  shareLink = environment.app_url;
  copySuccess = false;

  apps = [
    { name: 'Facebook', logo: '../../../../assets/img/facebook-logo.png' },
    //{ name: 'Instagram', logo: '../../../../assets/img/instagram-logo.png' },
    //{ name: 'TikTok', logo: '../../../../assets/img/tiktok-logo.png' },
    { name: 'Twitter', logo: '../../../../assets/img/twitter-logo.png' },
    { name: 'LinkedIn', logo: '../../../../assets/img/linkedin-logo.png' },
    { name: 'Gmail', logo: '../../../../assets/img/gmail-logo.png' },
    { name: 'Whatsapp', logo: '../../../../assets/img/whatsapp-logo.png' },
    //{ name: 'Youtube', logo: '../../../../assets/img/youtube-logo.png' },
    { name: 'Pinterest', logo: '../../../../assets/img/pinterest-logo.png' },
    { name: 'Telegram', logo: '../../../../assets/img/telegram-logo.png' },
    { name: 'Reddit', logo: '../../../../assets/img/reddit-logo.png' },
    { name: 'Tunblr', logo: '../../../../assets/img/pinterest-logo.png' }
  ];

  constructor(public dialogRef: MatDialogRef<ShareDialogComponent>) {}

  shareApp(name = null){
    if(name == "Facebook"){
        this.shareOnFacebook();
    } else if(name == "TikTok"){
        this.shareOnGmail();
    } else if(name == "Twitter"){
        this.shareOnTwitter();
    } else if(name == "LinkedIn"){
        this.shareOnLinkedIn();
    } else if(name == "Gmail"){
        this.shareOnGmail();
    } else if(name == "Whatsapp"){
        this.shareOnWhatsapp();
    } else if(name == "Youtube"){
        this.shareOnYouTube();
    } else if(name == "Pinterest"){
        this.shareOnPinterest();
    } else if(name == "Telegram"){
        this.shareOnTelegram();
    } else if(name == "Tunblr"){
        this.shareOnTunblr();
    } else if(name == "Reddit"){
        this.shareOnReddit();
    }
  }

  shareOnTelegram() {
    window.open(`https://t.me/share/url?url=`+this.shareLink, '_blank');
  }

  shareOnTunblr() {
    window.open(`https://www.tumblr.com/widgets/share/tool?canonicalUrl=`+this.shareLink, '_blank');
  }

  shareOnReddit() {
    window.open(`https://www.reddit.com/submit?url=`+this.shareLink, '_blank');
  }

  shareOnPinterest() {
    window.open(`https://pinterest.com/pin/create/button/?url=`+this.shareLink, '_blank');
  }

  shareOnWhatsapp() {
    window.open(`https://wa.me/?text=VotreTexteIci%20`+this.shareLink, '_blank');
  }

  shareOnFacebook() {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=`+this.shareLink, '_blank');
  }

  shareOnLinkedIn() {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=`+this.shareLink, '_blank');
  }

  shareOnTwitter() {
    const tweetText = "Regardez ceci !"; // Vous pouvez personnaliser le texte du tweet
    window.open(`https://twitter.com/intent/tweet?text=Message de test&url=`+this.shareLink, '_blank');
  }
  
  shareOnYouTube() {
    // Rediriger vers une vidéo YouTube spécifique
    const videoId = "dQw4w9WgXcQ"; // Exemple d'ID de vidéo
    window.open(`https://www.youtube.com/watch?v=`+this.shareLink, '_blank');
  }

  shareOnGmail() {
    const subject = "Découvrez ce super site !";  // Sujet du message
    const body = `Salut,\n\nJe voulais partager ce lien avec toi : \n\nCordialement.`;
    
    // Crée l'URL mailto avec le sujet et le corps du message
    const mailtoUrl = `mailto:?subject=corps du mail&body=`+this.shareLink;
    
    // Ouvre le client mail (Gmail ou tout autre client de messagerie configuré)
    window.location.href = mailtoUrl;
  }

  scrollLeft() {
    const container = document.querySelector('.app-list') as HTMLElement;
    container.scrollBy({ left: -100, behavior: 'smooth' });
  }

  scrollRight() {
    const container = document.querySelector('.app-list') as HTMLElement;
    container.scrollBy({ left: 100, behavior: 'smooth' });
  }

  copyLink() {
    navigator.clipboard.writeText(this.shareLink).then(() => {
      this.copySuccess = true;
      setTimeout(() => this.copySuccess = false, 2000); // Effacer après 2 secondes
    }).catch(err => {
      console.error('Erreur de copie : ', err);
      alert('Échec de la copie du lien.');
    });
  }
}
