import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from '../../auth/register/register.component';
import { LoginComponent } from '../../auth/login/login.component';
import { RecoverComponent } from '../../auth/recover/recover.component';
import { SetnewComponent } from '../../auth/setnew/setnew.component';
import { PrivacyComponent } from '../../pages/privacy/privacy.component';
import { TermsOfServiceComponent } from '../../pages/terms-of-service/terms-of-service.component';
import { ContactComponent } from '../../pages/contact/contact.component';
import { LegalComponent } from '../../pages/legal/legal.component';
import { HomeComponent } from '../../pages/home/home.component';
import { AuthGuard } from '../../auth/guards/auth.guard';
import { ProfileComponent } from '../../pages/profile/profile.component';
import { StatistiqueComponent } from 'src/app/statistique/statistique.component';
import { DiscoverviewComponent } from 'src/app/discoverview/discoverview.component';
import { ConfirmComponent } from 'src/app/auth/confirm/confirm.component';
import { RenewConfirmationComponent } from 'src/app/auth/renew-confirmation/renew-confirmation.component';
import { DashboardComponent } from 'src/app/project/dashboard/dashboard/dashboard.component';
import { SingleProjectComponent } from 'src/app/project/dashboard/single-project/single-project.component';
import { ActivateAccountcmpComponent } from 'src/app/activate-accountcmp/activate-accountcmp.component';
import { NotifsActionsBisComponent } from 'src/app/notifs-actions-bis/notifs-actions-bis.component';
import { CompteRenduRenunionComponent } from 'src/app/compte-rendu-renunion/compte-rendu-renunion.component';
import { JournalReunionComponent } from 'src/app/journal-reunion/journal-reunion.component';
import { JournalActionBisComponent } from 'src/app/components/journal-action-bis/journal-action-bis.component';
import { NotificationSettingComponent } from 'src/app/notification-setting/notification-setting.component';
import { EntrepriseManagerComponent } from 'src/app/entreprise-manager/entreprise-manager.component';
import { CompanyMemberComponent } from 'src/app/company-member/company-member.component';
import { JournalWorkflowComponent } from 'src/app/components/journal-workflow/journal-workflow.component';
import { HomesComponent } from 'src/app/project/dashboard/homes/homes.component';
import { DriveComponent } from 'src/app/project/dashboard/drive/drive.component';
import { RedirectComponent } from 'src/app/auth/redirect/redirect.component';

/*
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'reset/:token', component: SetnewComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'profile/:ID', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'confirm/:token', component: ConfirmComponent },
  { path: 'reconfirm', component: RenewConfirmationComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'single/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'single/:ID/:specMsg/:type', component: DashboardComponent, canActivate: [AuthGuard] }
];*/

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'Invitation', component: RedirectComponent },
  { path: 'acitivateAccount/:token', component: ActivateAccountcmpComponent },
  { path: 'login/:token', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'discoverview', component: DiscoverviewComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'reset/token/:token', component: SetnewComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },/*
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'notificationsSetting', component: NotificationSettingComponent, canActivate: [AuthGuard] },
  { path: 'profile/:ID', component: ProfileComponent, canActivate: [AuthGuard] },*/
  { path: 'confirm/:token', component: ConfirmComponent },
  { path: 'reconfirm', component: RenewConfirmationComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboardinst', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'homes', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'homes/:wlc', component: DashboardComponent, canActivate: [AuthGuard] },
  //{ path: 'statistique', component: StatistiqueComponent, canActivate: [AuthGuard] },
  { path: 'project/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'project/:ID/:openmsg', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'project/:ID/:openmsg/:idmsg', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'project/:ID/:openface', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'project/details/:ID/:specMsg/:type', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'faceTofacesProposal/details/:ID/:specMsg/:type', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'faceTofacesProposal/details/:ID/:specMsg/:type/:isProposal', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'faceTofacesReunion/details/:ID/:specMsg/:type', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'reunion/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'faceTofacesProposal/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'faceTofacesProposal/:ID/:openprop', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'faceTofacesReunion/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'faceTofacesReunion/:ID/:openmeet', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'plan/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'journal/actions', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'project/:ID/meet/:IDMeet', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'meet/:ID/face2face/:IDMeet', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'notifsActionsBis', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'JournalReunionComponent', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'compteRenduRenunion/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'journalActionBis', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'CompanyRegister', component: EntrepriseManagerComponent },
  { path: 'companyMember', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'JournalWorkflow', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'JournalService', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'JournalKpi', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'statistique', component: DashboardComponent, canActivate: [AuthGuard] },
  //{ path: 'drive', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'notificationsSetting', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'profile/:ID', component: DashboardComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', // Add options right here
  relativeLinkResolution: 'legacy',
  useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  HomeComponent,
  RegisterComponent,
  LoginComponent,
  EntrepriseManagerComponent,
  ContactComponent,
  LegalComponent,
  PrivacyComponent,
  RecoverComponent,
  SetnewComponent,
  TermsOfServiceComponent,
  ProfileComponent,
  StatistiqueComponent,
  DriveComponent,
  ConfirmComponent,
  RenewConfirmationComponent,
  DashboardComponent,
  SingleProjectComponent,
  ActivateAccountcmpComponent,
  NotifsActionsBisComponent,
  DiscoverviewComponent,
  CompteRenduRenunionComponent,
  JournalReunionComponent,
  NotificationSettingComponent,
  JournalActionBisComponent,
  JournalWorkflowComponent
];
